module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Careery","short_name":"Careery","description":"Join our game-changing service and gain the competitive edge you need to secure your next job.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/media/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e5a9698ca136fe7b5a9bcb519ceb84c7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P86X97R","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"careery-platform"}},"routeChangeEventName":"careery-route","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-75},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
