import './src/styles/style.css';
import '@fontsource/noto-sans';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/700.css';
// automatically reload offline site version.
export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};

// ES6 way
// Dynamically load the polyfill in your gatsby-browser.js:
//  for gatsby background
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
